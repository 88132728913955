<template>
  <div class="page-container">
    <div class="content-wrap" v-if="!maintenaceWindow">
      <TopNavBar
      v-bind:secondTierLoadingDone="secondTierLoadingDone"/>
      <b-container class="content" fluid="true">
        <b-row align-h="center">
          <b-col class="col-0 col-sm-0 col-md-1 col-lg-2 p-2"></b-col>
          <list
            v-on:secondTierLoadingDone="secondTierLoadingDone=true"
            :filter-by="'all'"
            class="col-12 col-sm-12 col-md-10 col-lg-8"
            style="min-width: 250px;"/>
          <b-col class="col-0 col-sm-0 col-md-1 col-lg-2 p-2"></b-col>
        </b-row>
      </b-container>
    </div>
    <div v-if="maintenaceWindow">
      <MaintenanceWindow/>
    </div>
    <GenderAppFooter/>
  </div>
</template>

<script>
// @ is an alias to /src
import TopNavBar from '../components/TopNavBar'
import List from '../components/List'
import GenderAppFooter from '../components/Footer'
import MaintenanceWindow from './MaintenanceWindow'
import { MAINTENANCE_WINDOW } from '@/env'

export default {
  name: 'Home',
  data () {
    return {
      secondTierLoadingDone: false,
      maintenaceWindow: false
    }
  },
  components: {
    GenderAppFooter,
    List,
    TopNavBar,
    MaintenanceWindow
  },
  created () {
    this.maintenaceWindow = MAINTENANCE_WINDOW === 'true'
  },

  methods: {
  }
}
</script>
<style scoped>
  .content {
    flex: auto;
  }
</style>
