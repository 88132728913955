<template>
<b-container class="content" fluid="true">
        <b-row align-h="center" class="bg-primary p-0">
          <div
            class="col-12 text-center p-5"
            style="min-width: 250px; height: 33vh">
              <b-img :src="logoPath" fluid alt="Responsive image" width="1500"></b-img>
          </div>
          <div
            class="col-12 text-center p-3"
            style="min-width: 250px; height: 33vh">
              <b-img :src="maintenaceWindowImage" fluid alt="Responsive image" width="500"></b-img>
          </div>
          <div
            class="col-12 text-center text-white mt-4 p-3 "
            style="min-width: 250px; height: 10vh">
          {{ $t('maintenance window') }}
          </div>
          <div
            class="col-12 text-center text-white p-3"
            style="min-width: 250px; height: 12vh">
          {{ $t('The responsible people are working on a solution') }}
          </div>
        </b-row>
      </b-container>
</template>

<script>
export default {
  name: 'MaintenanceWindow',
  data () {
    return {
      logoPath: '/img/logo-7kx1k.png',
      maintenaceWindowImage: '/img/maintenance-gendi.png'
    }
  }
}
</script>

<style scoped>

</style>
